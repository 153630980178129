import {createBrowserRouter} from "react-router-dom";

import Layout from "./routes/Layout";
import ErrorPage from "./error-page";
import {UnderConstruction} from "./components";
import HomePage from "./routes/HomePage";
import GameZone from "./routes/GameZone";
import About from "./routes/About/About";
import {Refund} from "./routes/Refund";
import {PrivacyPolicy} from "./routes/PrivacyPolicy";
import {loader as getImageFromAssetsLoader} from "./routes/GetImageFromAssets";
import {action as getImageFromAssetsAction} from "./routes/GetImageFromAssets";
import { TermsOfUse } from "./routes/TermsOfUse";
import { SocialPage } from "./routes/SocialPage";
import { Registration } from "./routes/Registration";
import { ResetPassword } from "./routes/ResetPassword";

const screenWidth = window.innerWidth;
const router = createBrowserRouter([
    {
        path: "/",
        element: <Layout/>,
        errorElement: <ErrorPage/>,
        children: [
            {index: true, element: <HomePage/>},
            {
                path: "downloadImage/:type",
                index: true,
                loader: getImageFromAssetsLoader,
                action: getImageFromAssetsAction,
                // errorElement: <ErrorPage />,
            },
            {
                path: "games/:name",
                element: <GameZone/>,
                errorElement: <ErrorPage/>,
                children: [
                    {
                        index: true,
                        element: <div>Initial Screen. Look at routes.jsx</div>,
                    },
                    {
                        path: ":gameName",
                        errorElement: <ErrorPage/>,
                        element: <UnderConstruction/>,
                    },
                ],
            },
            {
                path: "about",
                element: <About />,
            },
            {
                path: "refund_policy",
                element: <Refund />,
            },
            {
                path: "privacy_policy",
                element: <PrivacyPolicy />,
            },
            {
                path: "terms_of_use",
                element: <TermsOfUse />,
            },
            {
                path: "registration",
                element: <Registration />,
            },
            {
                path: "reset_password/:id/:token",
                element: <ResetPassword />,
            },

        ],
    },
    {
        path: "info",
        element: <SocialPage />,
    },
    {
        path: "menu",
        element: <SocialPage />,
    },
]);

export default router;
